<template>
    <div class="container card">
       <div class="table-wrapper">
               <div class="table-title">
                    <div class="row">
                    <div class="col-md-12">
                        <h2 class="text-center"><b>Edit Product</b></h2>
                        <button @click="back"  class="btn btn-success">Back</button>
                    </div>
                </div>
               </div>
            </div>
        <section v-if="step ===1">
        <div class="row justify-content-center">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                        <div class="form-group">
                        <label for="brand-name">Brand Name</label>
                        <input type="text" placeholder="Enter brand name" id="brand-name" class="form-control" v-model="product.brand_name">
                    </div>
                    <div class="form-group">
                        <label for="generic-name">Generic Name</label>
                        <input type="text" placeholder="Enter generic name" id="generic-name" class="form-control" v-model="product.generic_name">
                    </div>
                    <div class="form-group">
                        <label for="other-name">Other Name</label>
                        <input type="text"  placeholder="Enter other name" id="other-name" class="form-control" v-model="product.other_names">
                    </div>
                    <div class="form-group">
                        <label for="sales-markets">Sales Market Areas</label>
                             <multiselect 
                                id="sales-markets"
                                v-model="product.sales_markets" deselect-label="Can't remove this value" track-by="name" 
                                label="name" placeholder="Select Maket" :options="markets" :allow-empty="false" :multiple="true" >
                          </multiselect>
                    </div> 
                    <div class="form-group">
                                <label for="certification_number">HS Code</label>
                                 <multiselect 
                                 id="certification_number"
                                v-model="product.hs_code" deselect-label="Can't remove this value" track-by="hs_code" 
                                label="hs_code" placeholder="Select HS Code" :options="HsCodes" :allow-empty="false">
                                </multiselect>
                    </div>
                     <div class="row justify-content-end">
                        <div class="py-2 py-3">
                            <button v-if="step != totalStep"  @click.prevent="nextstep" class="btn btn-danger btn-block my-btn float-right">Next</button>  
                        </div>     
                </div>
             
                </div>    
            </div>
        </div>
    </div>
    </section>
        <section v-if="step ===2">
        <div class="row justify-content-center">
        <div class="col-md-12">
            <div class="card">
                <div class="card-body">
                      <div class="form-group">
                            <label for="gtin">Global Trade Item Number</label>
                            <input type="number" v-model="product.gtin" placeholder="Enter GTIN Here" id="gtin" class="form-control">
                    </div>
                    <div class="form-group">
                        <label for="upc">European Article Number</label>
                        <input type="number" v-model="product.ean" placeholder="Enter EAN Here" id="upc" class="form-control"> 
                    </div>
                    <div class="form-group">
                        <label for="upc">Universal Product Code</label>
                        <input type="number" v-model="product.upc" placeholder="Enter UPC Here" id="upc" class="form-control"> 
                    </div>
                     <div class="form-group">
                                <label for="raw_materials_list">Raw Materials List</label>
                                <input class="form-control" type="text" placeholder="rice, maize etc etc" v-model="product.raw_materials_list" />
                    </div>
                        <!-- DYNAMIC FORM CONTENT -->
                        <fieldset class="border ">
                            <legend class="w-auto text-dark">Manufacturing Countries and Locations</legend>
                            <div class="form-row" style="border:1px solid black:" v-for="(manifacture,key) in product.manifactures" :key="key">
                         <div class="col-5">
                             <div class="form-group">
                                <label for="raw_materials_list">Manufacturing country</label>
                                <country-select required :countryName="true" class="form-control" v-model="manifacture.manufacturing_countries" :country="manifacture.manufacturing_countries" topCountry="US" />
                            </div>
                         </div>
                        <div class="col-5">
                             <div class="form-group">
                                <label for="raw_materials_list">Manufacturing Location</label>
                                <region-select required :countryName="true" :regionName="true" class="form-control" v-model="manifacture.manufacturing_locations" :country="manifacture.manufacturing_countries" :region="manifacture.manufacturing_locations" />
                            </div>
                        </div>
                         <div class="col-1" style="padding-top:1.9em">
                             <label for=""></label>
                            <button v-if="product.manifactures.length > 1"  @click="deleteManifacture(key)" class="btn btn-danger mx-2"> x</button>
                        </div>
                     </div>
                      <div class="">
                            <button    @click="addManifacture" class="btn btn-success my-3 mx-2"><i class="fas fa-plus"></i> Add</button>
                        </div>
                        </fieldset>
                        <!-- END OF DYNAMIC FORM CONTENT -->
                    <div class="row justify-content-end">
                    <div class="col-md-3 py-2">
                        <button v-if="step==2"  @click.prevent="prevstep" class="btn btn-danger btn-block float-right my-btn">Previous</button>   
                    </div>
                    <div class="col-md-3 py-2  align-item-end">
                        
                        <button v-if="step==2"  @click.prevent="nextstep" class="btn btn-danger btn-block float-right my-btn">Next</button>   
                    </div> 
                </div>
                </div>    
            </div>
        </div>
    </div>
    </section>
    <section v-if="step === 3">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="card">
                    <div class="card-body">
                     <div class="form-group">
                        <label for="parking">Parking Size</label>
                        <input type="number" v-model="product.parking_size" placeholder="Enter Parking Size" id="parking" class="form-control"> 
                    </div>
                  
                    
                    <div class="form-group">
                                <label for="health_and_safety_registeration_number">Health and Safety Registeration Number</label>
                                <multiselect @select="healthNumber = true"
                                v-model="product.health_and_safety_registeration_number" deselect-label="Can't remove this value"  
                                 placeholder="Select Health and Safety Code" :options="healthAndSafetyTypes">
                                </multiselect>    
                    </div>
                    <div class="form-group">
                        <label for="parking">Add Health and Safety Registeration Number</label>
                        <input type="number" v-model="product.healthNumber" placeholder="Enter number" id="parking" class="form-control"> 
                    </div>
                    <div class="form-group">
                                <label for="standards_registeration_number">Standard Registeration Number</label>
                                  <multiselect 
                                  @select="standardNumber = true"
                                    v-model="product.standards_registeration_number" deselect-label="Can't remove this value" 
                                    placeholder="Select Standard Registeration Types" :options="standardRegisterationTypes">
                                </multiselect>
                    </div>
                    <div class="form-group">
                        <label for="parking">Add Standard Registeration Number</label>
                        <input type="number" v-model="product.standardNumber" placeholder="Enter Number" id="parking" class="form-control"> 
                    </div>
                     <div class="form-group" >
                                <label  for="certification_number">Certification Number</label>
                                <multiselect 
                                @select="certificationNumber = true"
                                v-model="product.certification_number" deselect-label="Can't remove this value" 
                                placeholder="Select Certification Registeration Types" :options="certificationTypes">
                                </multiselect>
                    </div>
                    <div class="form-group" >
                        <label for="parking">Add Certification Number</label>
                        <input type="number" v-model="product.certificationNumber" placeholder="Enter Number" id="parking" class="form-control"> 
                    </div>
                    <div class="row justify-content-end">
                    <div class="col-md-3 py-2">
                        <button v-if="step==3"  @click.prevent="prevstep" class="btn btn-danger btn-block float-right my-btn">Previous</button>   
                    </div>
                    <div class="col-md-3 py-2 align-item-end">
                        
                        <button type="submit" v-if="step==3"  @click.prevent="updateProduct()" class="btn btn-danger btn-block float-right mr-1 my-btn ">
                            <span v-if="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                             Submit
                        </button>   
                    </div> 
                </div>
                </div>
                </div>
            </div>
        </div>
    </section>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'
import toasterMixin from '@/mixins/toasterMixin.js'

    export default {
        components: {
         Multiselect   
        },
        data() {
            return {
                totalStep: 3,
                step:1,
                standardNumber:false,
                certificationNumber:false,
                healthNumber:false,
                error : null,
                success: null,
                loading: null,
                 product:{
                    healthNumber:'',
                    standardNumber:'',
                    certificationNumber:'', 
                    brand_name:"",
                    generic_name:"",
                    other_names:"",
                    sales_markets:[],
                    gtin:"",
                    upc:"",
                    ean:"",
                    parking_size:"",
                    raw_materials_list:"",
                    hs_code:"",
                    health_and_safety_registeration_number:'',
                    standards_registeration_number:'',
                    certification_number:'',
                    manifactures: [
                        {
                              manufacturing_countries:'',
                              manufacturing_locations:'',
                        }
                    ]
                    },
                
            }
        },
        computed:{
            ...mapGetters(['HsCodes','markets','standardRegisterationTypes','certificationTypes','healthAndSafetyTypes'])
        },
        mounted(){
            this.$store.dispatch("fetchMarket")
            this.$store.dispatch('fetchStandardRegisterationTypes')
            this.$store.dispatch('fetchHealthAndSafetyRegisterationTypes')
            this.$store.dispatch('fetchCertificationTypes')
            this.$store.dispatch('fetchHsCodes')
                
        },
        methods:{
            back(){
                this.$router.replace(`/entity/${this.$route.params.id}/product/:${this.$route.params.productID}`)
            },
            addManifacture() {
                this.product.manifactures.push({
                    manufacturing_countries:'',
                    manufacturing_locations:'',
                })
            },
            deleteManifacture(key) {
                if(this.product.manifactures.length === 1) return
                this.product.manifactures.splice(key,1)
            },
            nextstep(){
                this.step ++
           },
            prevstep(){
                this.step --
            },
            updateProduct(){
                this.loading = true
                this.error = null
                this.success = null

                const sales_markets = []
                this.product.sales_markets.forEach(element => {
                    sales_markets.push(element.name)
                });
                const manufacturing_countries = []
                const manufacturing_locations = []

                this.product.manifactures.forEach(manifucture => {
                    manufacturing_countries.push(manifucture.manufacturing_countries)
                    manufacturing_locations.push(manifucture.manufacturing_locations)
                })
                // console.log(manufacturing_locations,manufacturing_countries)
                const product = {
                        "brand_name": this.product.brand_name,
                        "generic_name": this.product.generic_name,
                        "other_names": this.product.other_names ,
                        "sales_markets": sales_markets,
                        "gtin": this.product.gtin,
                        "upc": this.product.upc,
                        "ean": this.product.ean,
                        "raw_materials_list": this.product.raw_materials_list,
                        "manufacturing_countries": manufacturing_countries,
                        "manufacturing_locations": manufacturing_locations,
                        "hscode_id": this.product.hs_code.id,
                        "parking_size": this.product.parking_size,
                        "health_and_safety_registeration_number": [this.product.health_and_safety_registeration_number, this.product.healthNumber],
                        "standards_registeration_number": [this.product.standards_registeration_number, this.product.standardNumber],
                        "certification_number": [this.product.certification_number, this.product.certificationNumber]
                    }
                 const data = {
                     id:this.$route.params.id,
                     product:product
                 }
                 console.log(data)
                // DISPATCHING DATA TO THE BACKEND
                this.$store.dispatch('updateProduct', data)
                    .then(response => {
                        if(response.status == 200) {
                            this.loading = false
                            this.toast({message:'Product created', type:'success'})

                        }     
                    }).catch(err => {
                        if(err?.response?.data == undefined) {
                            this.loading = false
                            this.toast({message:'Network error. Check your network connection and try again.', type:'error'})
                        }
                    })
            }
        },
        created() {
            this.$store.dispatch('fetchUserProducts', this.$route.params.id)
                .then(response => {
                    const newManufactures = []
                    const newMarket = []
                    const products = response.data.products
                    const product = products.find( product => product.id == this.$route.params.productID)

                    // update the form with data
                    this.product.brand_name = product.brand_name
                    this.product.generic_name = product.generic_name
                    this.product.other_names = product.other_names
                    this.product.hs_code = product.hscode
                    this.product.gtin = product.gtin
                    this.product.ean = product.ean
                    this.product.upc = product.upc
                    this.product.raw_materials_list = product.raw_materials_list
                    this.product.parking_size = product.parking_size

                    for(let i = 0; i < product.health_and_safety_registeration_number.length; i++) {
                        this.product.health_and_safety_registeration_number = product.health_and_safety_registeration_number[0] 
                        this.product.healthNumber = product.health_and_safety_registeration_number[1]
                    }
                    for(let i = 0; i < product.standards_registeration_number.length; i++) {
                        this.product.standards_registeration_number = product.standards_registeration_number[0] 
                        this.product.standardNumber = product.standards_registeration_number[1]
                    }
                    for(let i = 0; i < product.certification_number.length; i++) {
                        this.product.certification_number = product.certification_number[0] 
                        this.product.certificationNumber = product.certification_number[1]
                    }
                    for(let i = 0; i < product.manufacturing_countries.length; i++) {
                        for(let j = 0; j <= i; j++) {
                             newManufactures.push({
                                manufacturing_countries:product.manufacturing_countries[i],
                                manufacturing_locations:product.manufacturing_locations[j]
                        })
                        }
                    }
                    product.sales_markets.forEach(market => {
                        newMarket.push({
                            name:market
                        })
                    })
                    this.product.manifactures = newManufactures
                    this.product.sales_markets = newMarket
                })
        }
    }
</script>

<style  scoped>
  label{
      color:#000;
  }
  .table-wrapper{
    background: #fff;
    padding: 20px 25px;
    margin: 30px 0;
    border-radius: 2px;
    box-shadow: 0 1px 1px rgba(0, 0, 0,0.247);
}
.table-title{
    padding-bottom: 15px;
    background: linear-gradient(40deg,#ff2020, #05ffa3);
    color: #fff;
    padding: 16px 30px;
    margin: -20px -25px 10px;
    border-radius: 1px 1px 0 0;
    box-shadow: 0 1px 1px rgba(0, 0, 0,0.247);
}
.table-title h2{
    margin: 5px 0 0;
    font-size: 24px;
}
.my-btn{
    width:200px;
}
fieldset{
    width:inherit; 
    padding:0 10px;
    border-bottom:none;
}
</style>